import { createApp } from 'vue';
import App from '@components/App';
import heyui from 'heyui';
import initHeyui from '@js/config/heyui-config';
import router from '@js/config/router-config';
import store from '@js/vuex/store';
import initComponents from '@js/vue/components';
import initFilters from '@js/vue/filters';
import initDirectives from '@js/vue/directives';
import '@js/vue/filters';
import dataV from '@jiaminghi/data-view';




import axiosInstance from '@js/common/axios';


// Vue.use(SlideVerify);

require('./css/app.less');

// 开发环境判断
// process.env.NODE_ENV == 'development'

// 使用mock文件， 自己开发的时候请删除
// require('./mock/index');

const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;

app.config.globalProperties.$requesturl = 'http://saas.test.shangtiiot.cn:8086';
app.config.globalProperties.$requesturl1 = 'wss://saas.shangtiiot.cn:8088/st_sys_vm/websocket/get_signin_info/';

// app.config.globalProperties.$requesturl = 'https://saas.shangtiiot.cn:8088';
// app.config.globalProperties.$requesturl1 = 'ws://saas.test.shangtiiot.cn:8086/st_sys_vm/websocket/get_signin_info/';


// app.config.devtools = false; 

app.use(heyui);
app.use(store);
app.use(router);
app.use(dataV);
initComponents(app);
initFilters(app);
initDirectives(app);
initHeyui();

app.mount('#app');
export default app;
