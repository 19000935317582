import utils from '@common/utils';

const fullMenus = [{
    title: '单店版',
    key: 'Home',
    icon: 'icon-home',
  },
  {
    title: '综合馆',
    key: 'Datacomprehensive',
    icon: 'icon-order-for-goods',
  },




  // {
  //   title: '管理租户',
  //   key: 'Tenant',
  //   icon: ' icon-minus-cirlce'
  // },
  // {
  //   title: '管理工作人员',
  //   key: 'Staff',
  //   icon: ' icon-minus-cirlce',
  // },
  // {
  //   title: '管理场馆',
  //   key: 'Venue',
  //   icon: ' icon-minus-cirlce'
  // },

  // {
  //   title: '顾客管理',
  //   key: 'Customer',
  //   icon: 'icon-Customer-Management'
  // },
  // {
  //   title: '商品管理',
  //   key: 'Commodity',
  //   icon: 'icon-commodity',
  // },


  // {
  //   title: '营销活动',
  //   key: 'Marketing',
  //   icon: 'icon-finance',
  // },

  // {
  //   title: '优惠券',
  //   key: 'Coupons',
  //   icon: 'h-icon-link',
  // },

  // {
  //   title: 'API管理台',
  //   key: 'Apimanage',
  //   icon: 'icon-order-for-goods',
  // },
  // {
  //   title: '数据报表',
  //   key: 'Datareport',
  //   icon: 'icon-order-for-goods',
  // },
  // {
  //   title: '财务管理',
  //   key: 'Finmanage',
  //   icon: 'icon-refund',
  //   children: [{
  //       title: '销售订单',
  //       key: 'Finmanageorder'
  //     },
  //     {
  //         title: '销售订单',
  //         key: 'Finmanageorderclerk'
  //       },
  //     {
  //       title: '支付交易',
  //       key: 'Finmanagepay'
  
  //     }
  //   ]
  // },
  // {
  //   title: '运动处方配置',
  //   key: 'ExrxConfig',
  //   icon: ' icon-bell',
  //   children: [{
  //       title: '影响因子',
  //       key: 'ExrxConfigFactorList'
  //     },
  //     {
  //       title: '阶段设置',
  //       key: 'ExrxConfigStageList'

  //     },
  //     {
  //       title: '设备强度',
  //       key: 'ExrxConfigIntensityList'
  //     }
  //   ]
  // },
  // {
  //   title: '数据报表',
  //   key: 'Report',
  //   icon: 'icon-order-for-goods',
  //   children: [{
  //       title: '运营报表',
  //       key: 'Daily'
  //     },
  //     {
  //       title: '上课统计',
  //       key: 'Statistics'
  
  //     }
  //   ]
  // },
  
  // {
  //   title: '场馆数据',
  //   key: 'VenueData',
  //   icon: 'icon-bell', },
    
  //   {
  //     title: '全屋物联',
  //     key: 'WholeHouseIoT',
  //     icon: 'h-icon-link', },

];

const getMenus = function(menuIdList = []) {
  return getAuthMenu(fullMenus, menuIdList);
};

let getAuthMenu = (menus, menuIdList) => {
  let configMenu = [];
  for (let menu of menus) {
    let m = utils.copy(menu);
    if (menuIdList.indexOf(m.key) > -1) {
      configMenu.push(m);
    }
    if (menu.children && menu.children.length) {
      m.children = getAuthMenu(menu.children, menuIdList);
    }
  }
  return configMenu;
};

const getKeys = function(menus) {
  let keys = [];
  for (let menu of menus) {
    keys.push(menu.key);
    if (menu.children && menu.children.length) {
      keys.push(...getKeys(menu.children));
    }
  }
  return keys;
};

let fullMenuKeys = getKeys(fullMenus);

const isAuthPage = function(menus, name) {
  if (fullMenuKeys.indexOf(name) > -1 && menus.indexOf(name) == -1) {
    return false;
  }
  return true;
};

export {
  getMenus,
  fullMenus,
  fullMenuKeys,
  isAuthPage
};
