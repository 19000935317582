import axios from 'axios';
import qs from 'qs';
import utils from '@common/utils';

// console.log(',',process.env.VUE_APP_BASE_URL);
const instance = axios.create({
  // 测试环境
  baseURL: 'http://saas.test.shangtiiot.cn:8086/sport/',
  // baseURL: 'http://47.102.195.22:8086/st_sys_vm/',
          // baseURL: 'http://localhost:15004/',
  // 正式环境
  // baseURL: 'http://dm.shangtiiot.cn:8086/sport/',
  // withCredentials:true,
  // baseURL: 'http://192.168.2.19:8086/st_sys_vm/',
  timeout: 60000,
  //这告诉服务器请求正文将发送为JSON格式。
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [function (data, headers) {
    
  if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // 把一个参数对象格式化为一个字符串
        return qs.stringify(data);
      } else if (headers['Content-Type'] === 'multipart/form-data;charset=UTF-8') {
        return data;
      } else {
        headers['Content-Type'] = 'application/json';
      }
      return JSON.stringify(data);
  return data;
  }],
  
    // transformRequest: [function (data) {
    // data = qs.stringify(data);
    // return data;
    // }]
  

});
// 请求拦截器
instance.interceptors.request.use(config =>{
    // config.withCredentials=true;
    // console.log(config);
    // console.log(utils.getLocal('token'));
    // console.log(sessionStorage['token']);
    
    
    if(sessionStorage['token']!=undefined){
      config.headers.Authorization='Bearer '+utils.getLocal('token');
      // token过期处理
      // var date = new Date().getTime();
      // var time=date+18000000;
      // utils.saveLocal('tokentime', time);
      
    }else{
      
    }
    // util/get_auth_code_url
    if(config.url=='util/get_token'||config.url=='util/get_login_user_details_info'||config.url=='util/get_auth_code_url'||config.url=='login/retrieve_person_info'){
          
          // config.baseURL='https://saas.shangtiiot.cn:8088/st_sys_vm/';
          
          config.baseURL='http://saas.test.shangtiiot.cn:8086/st_sys_vm/';
       
        }else{
          
          
        }
    
    
    if (config.url=='/customer/update_customer_face'||config.url=='finance/import_order_data'||config.url=='synthetical/update_img_url') {
      console.log('Content-Type修改');
      // console.log(config);
      config.headers['Content-Type']='multipart/form-data;charset=UTF-8';
    }
    
    if (config.url=='finance/export_order_data'||config.url=='finance/export_check_data'||config.url=='daily/export_member_report'||config.url=='finance/export_super_data') {
      // config.headers['Content-Type']='multipart/form-data;charset=UTF-8';
      // console.log(config);
      config.responseType='blob';
    }
    
    // console.log('修改' + config.url);
    if (config.url=='qingke/get_device_properties_set' || config.url=='qingke/get_scene_exec') {
       // console.log('Content-Type修改');
      config.headers['Content-Type']='application/json';
    }
    
    
    if (config.url=='logout/mylogout') {
      // config.withCredentials=true;
      // config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
      config.baseURL='http://saas.test.shangtiiot.cn:8086/';
      // config.baseURL='https://saas.shangtiiot.cn:8088/';
    }
	
	
	if (config.url=='logout/sport_logout') {
	  // config.withCredentials=true;
	  // config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
	  config.baseURL='http://saas.test.shangtiiot.cn:8086/';
	  // config.baseURL='https://saas.shangtiiot.cn:8088/';
	}

    return config;
});

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // console.log(response);
    // window.location = '/login?type=1';
    if (response.config.url=='finance/export_order_data'||response.config.url=='finance/export_check_data'||response.config.url=='daily/export_member_report'||response.config.url=='finance/export_super_data') {
      // console.log(1111);
      return response;
    }
    return response.data;
  },
  error => {
    if (error.response) {
      const status = error.response.status;
      
      if (status === 401) {
        // 处理未授权错误
        console.log('Unauthenticated error');
        
        // return;
        // http://localhost:8080/sport_web/login?code=3miyk5
        window.location = '/login';
        return error.response;
      } else if (status === 404) {
        // 处理找不到资源错误
        console.log('Resource not found error');
      } else {
        // 处理其他错误
        console.log('Other error');
      }
      // 跳转到错误页面
      // window.location.href = '/error.html';
    }
    return Promise.reject(error);
  }
);
//把 instance这个方法暴露出去
export default instance;
